import React from 'react';
import { Container } from 'react-bootstrap';

function Footer() {
  return (
    <footer className="bg-dark text-white mt-5 p-4 text-center">
      <Container>
        <p className="mb-0">&copy; 2024 ChihuaNews.com</p>
      </Container>
    </footer>
  );
}

export default Footer;