import { Container, Row, Col, Button, Form, Spinner, Alert, Card, FormControl} from 'react-bootstrap';
import React, { useEffect, useState} from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { getActiveCategories } from '../../services/categories';
import { getActiveSubcategories } from '../../services/subcategories';
import { saveDraftNews, getNewsById, updateNews, uploadFiles } from '../../services/news';
import Loading from '../../components/layout/Loading';
import { getStatuses } from '../../services/statuses'
import FilesContainerEdit from '../../components/news/FilesContainerEdit'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';


function AddAndEditNews () {
    const navigate = useNavigate();
    let id = 0;
    if(localStorage.getItem('news_id') == null || localStorage.getItem('news_id') == undefined )
    {
        id = 0;        
    }    
    else
    {
        id = parseInt(localStorage.getItem('news_id'));        
    }
    const toolbarOptions = [
        ['link'],
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ 'header': 1 }, { 'header': 2 }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'font': [] }],
        [{ 'align': [] }],
        ['clean']
      ];
      const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true); 
      const [isLoading, setIsLoading] = useState(false);    
      const [isUploadingFiles, setIsUploadingFiles] = useState(false);    
      const [uploadFilesResult, setUploadFilesResult] = useState(null);
      const [categories, setCategories] = useState([]);
      const [subcategories, setSubcategories] = useState([]);
      const [editorContent, setEditorContent] = useState('');
      const [selectedFiles, setSelectedFiles] = useState([]);
      const [statuses, setStatuses] = useState([]);
      const [reloadFilesContainer, setReloadFilesContainer] = useState(false);
      const redirectHome = () => {
        navigate('/')
      }
      const handleEditorChange = (content, delta, source, editor) => {
        const htmlContent = editor.getHTML();  // Obtener el contenido en formato HTML
        const jsonContent = editor.getContents();  // Obtener el contenido en formato JSON (Delta)
        const plainText = editor.getText();
        setEditorContent(content);
        setForm({
          ...form,
          description: plainText,
          descriptionJson: JSON.stringify(jsonContent),
          descriptionHtml: htmlContent
        });    
      };
      const handleInputChange = (e) => {
        const { name, value } = e.target;       
        setForm({
          ...form,
          [name]: value,
        });       
      };
      const onUpdateNews = ()=>{
        updateNews(form).then((result) => {
            if(result.success)
            {
                toast.success("Se actualizó correctamente")
            }
            else
            {
                toast.error("Algo salio mal")
            }
        })
      }
    const [form, setForm] = useState({
        id: id, 
        title: '',
        social_title: '',
        short_description:'',
        description:'',
        descriptionJson: '',
        descriptionHtml:'',
        category_id:0,
        subcategory_id:0,
        embedded_code:'',
        status_id:0
    });
    const handleFileChange = (e) => {
        setSelectedFiles(e.target.files); // Guardar los archivos seleccionados en el estado
        if(e.target.files && e.target.files.length > 0)
        {
            setUploadButtonDisabled(false);
        }
        else
        {
            setUploadButtonDisabled(true);
        }
    };
    const onUploadFiles = () => {
        setIsUploadingFiles(true);
        setUploadFilesResult(null)
        var data = new FormData()
        for (let i = 0; i < selectedFiles.length; i++) {
            data.append(`file${i}`, selectedFiles[i])
        }
        uploadFiles(data, form.id).then((result) => {
            setIsUploadingFiles(false);
            setUploadFilesResult(result.success)
            setSelectedFiles([]);
            setReloadFilesContainer(!reloadFilesContainer)
        })
    }
    const saveDraft = () => {
        setIsLoading(true);
        saveDraftNews(form).then((data)=>{
            setForm({
                ...form,
                ["id"]: data.id,
              });          
              setIsLoading(false)  ;  
        })
    }
    useEffect(() => {
        if(parseInt(form.category_id) !== 0)
        {
            getActiveSubcategories(parseInt(form.category_id)).then((data)=>{
                setSubcategories(data);
            })
        }   
        else
        {
            setSubcategories([]);
        }     
    },[form.category_id])
    const loadDataToForm = (data) => {

        setForm({
            id: id, 
            title: data.title,
            social_title: data.social_title,
            short_description:data.short_description,
            description:data.description,
            descriptionJson: data.descriptionJson,
            descriptionHtml:data.descriptionHtml,
            category_id:data.category_id,
            subcategory_id:data.subcategory_id,
            embedded_code:data.embedded_code,
            status_id: data.status_id
        });
        setEditorContent(data.descriptionHtml ? data.descriptionHtml: data.description)     
    }
    
    useEffect(() => {    
        getStatuses().then((data) => {
            setStatuses(data)
        });    
        getActiveCategories().then((data)=>{
            setCategories(data);
        })    
        if(id !== 0)
        {
            getNewsById(id).then((data)=>{      
                console.log(data)          
                if(data.subcategory_id !== null && data.subcategory_id !== 0)
                {
                    getActiveSubcategories(parseInt(form.category_id)).then((data)=>{                        
                        setSubcategories(data);
                    }).finally(()=>{
                        loadDataToForm(data);
                    })
                }                                
                else
                {
                    loadDataToForm(data);
                }
            });
        }
        
       
    },[])
    return (<>
        <Container  className='mb-5'>
                <Row className='justify-content-center mt-3'>
                        <Col xl={6}>
                                <Card>
                                    <Card.Header>
                                        Nota
                                    </Card.Header>
                                    <Card.Body>
                                    <Row >
                                        <Col xl={12}>
                                            <Form.Group>
                                                <Form.Label>Título</Form.Label>
                                                
                                                <Form.Control type="text" id='title' name='title' placeholder='Título' value={form.title} onChange={handleInputChange}  />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col xl={12}>
                                            <Form.Group>
                                                <Form.Label>Título Social</Form.Label>
                                                <Form.Control type="text" id='social_title' name='social_title' placeholder='Título Social' value={form.social_title} onChange={handleInputChange}   />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col xl={12}>
                                            <Form.Group>
                                                <Form.Label>Sumario</Form.Label>
                                                <Form.Control type="text" id='short_description' name='short_description' placeholder='Sumario'  value={form.short_description}  onChange={handleInputChange}   />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col xl={12}>
                                            <label className="font-weight-bold">Nota:</label>
                                            <ReactQuill
                                            value={editorContent}
                                            onChange={handleEditorChange}
                                            modules={{ toolbar: toolbarOptions }}
                                            theme="snow"
                                            placeholder="Escribe la nota aquí..."
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col xl={12}>
                                            <Form.Group>
                                                <Form.Label>Código para embebidos</Form.Label>
                                                <Form.Control
                                                    as="textarea" // Esto convierte el control en un <textarea>
                                                    id='embedded_code'
                                                    name='embedded_code'
                                                    placeholder='Código para embebidos'
                                                    onChange={handleInputChange}
                                                    value={form.embedded_code}
                                                    rows={5}
                                                    />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col xl={6}>
                                        <Form.Group controlId="formRole">
                                                    <Form.Label>Categoría *:</Form.Label>
                                                    <Form.Control
                                                        as="select"
                                                        name="category_id"
                                                        value={form.category_id}
                                                        onChange={handleInputChange}
                                                    >
                                                        <option key={0} value={0}>Seleccionar categoría</option>
                                                        {categories.map((category) => (
                                                                <option key={category.id} value={category.id}>{category.name}</option>
                                                        ))}
                                                    </Form.Control>
                                                </Form.Group>
                                        </Col>
                                        <Col xl={6}>
                                        <Form.Group controlId="formRole">
                                                    <Form.Label>Subcategoria *:</Form.Label>
                                                    <Form.Control
                                                        as="select"
                                                        name="subcategory_id"
                                                        value={form.subcategory_id}
                                                        onChange={handleInputChange}
                                                    >
                                                        <option key={0} value={0}>Seleccionar subcategoría</option>
                                                        {subcategories.map((category) => (
                                                                <option key={category.id} value={category.id}>{category.name}</option>
                                                        ))}
                                                    </Form.Control>
                                                </Form.Group>
                                        </Col>
                                    </Row>
                                    {
                                        form.id !== 0 ? 
                                        <Row className='mt-3'>
                                            <Col xl={12}>
                                                    <Form.Group controlId="formRole">
                                                        <Form.Label>Status *:</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            name="status_id"
                                                            value={form.status_id}
                                                            onChange={handleInputChange}
                                                        >w
                                                            {statuses.map((status) => (
                                                                    <option key={status.id} value={status.id}>{status.name}</option>
                                                            ))}
                                                        </Form.Control>
                                                    </Form.Group>
                                            </Col>
                                        </Row> :
                                        null
                                    }
                                    {
                                        isLoading ? 
                                        <Loading/> :
                                        form.id == 0 ? 
                                        <Row className=' mt-3'>
                                            <Col xl={12} className='text-left'>
                                                <Button className='m-1' variant='secondary' onClick={redirectHome}>Cancelar</Button>    
                                                <Button className='m-1' variant='success'  onClick={saveDraft} >Guardar</Button>              
                                            </Col>                
                                        </Row> :
                                        null

                                    }
                                     {
                                        isLoading ? 
                                        <Loading/> :
                                        form.id !== 0 ? 
                                        <Row className=' mt-3'>
                                        <Col xl={12} className='text-left'>
                                            <Button className='m-1' variant='secondary' onClick={redirectHome} >Cancelar</Button>    
                                            <Button className='m-1' variant='success'  onClick={onUpdateNews} >Actualizar</Button>              
                                        </Col>                
                                        </Row>:
                                        null

                                    }
                                    
                                    </Card.Body>
                                </Card>
                        </Col>
                    </Row>
            
            {
                form.id == 0 ? 
                null: 
                <>
                   <Row className='justify-content-center mt-3'>
                        <Col xl={6}>
                            <Card>
                                <Card.Header>
                                    Multimedia
                                </Card.Header>
                                <Card.Body>
                                    <Row className='justify-content-center mt-3'>
                                        <Col xl={8}>
                                            <Form.Group>
                                                <Form.Label>Subir archivos</Form.Label>
                                                <Form.Control
                                                    type="file"  // Esto reemplaza el uso de Form.File
                                                    id="fileUpload"
                                                    multiple  // Permite seleccionar múltiples archivos                                
                                                    onChange={handleFileChange}  // Manejar la selección de archivos
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className='d-flex justify-content-center align-items-center' xl={4}>
                                            <Button variant='info' disabled={uploadButtonDisabled} onClick={onUploadFiles} > 
                                                { 
                                                isUploadingFiles ?
                                                <Spinner animation="grow" /> :                  
                                                "Subir archivos"
                                                }
                                            </Button>
                                        </Col>
                                    </Row>
                                    {
                                        uploadFilesResult == null ? 
                                        null : (
                                            uploadFilesResult == true ? 
                                            <Row className='justify-content-center mt-3'>
                                                <Col xl={12}> 
                                                    <Alert variant="success">
                                                    Los archivos se han subido exitosamente.
                                                    </Alert>
                                                </Col>
                                            </Row> :
                                            <Row className='justify-content-center mt-3'>
                                            <Col xl={12}> 
                                                <Alert variant="warning">
                                                Hubo un problema al subir los archivos. Por favor, inténtalo de nuevo.
                                                </Alert>
                                            </Col>
                                        </Row> 
                                        )                                    
                                    }
                                    <FilesContainerEdit news_id={form.id} reloadFilesContainer={reloadFilesContainer} />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </>
                
            }
        </Container>
        <ToastContainer />
    </>)
}


export default AddAndEditNews;