import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Login from './pages/auth/Login';
import Signup from './pages/auth/Signup';
import ActivateAccount from './pages/auth/ActivateAccount';
import PasswordReset from './pages/auth/PasswordReset';
import PasswordResetRequest from './pages/auth/PasswordResetRequest';
import AddAndEditNews from './pages/news/AddAndEditNews';
import Home from './pages/news/Home';
import Manage from './pages/users/Manage';
import EditUser from './pages/users/EditUser';
import ProtectedRoute from './components/layout/ProtectedRoute';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer'
import ManageSections from './pages/news/ManageSections';
import ImportNewsFromAI from './pages/news/ImportNewsFromAI';



import './App.css';

function App() {
  return (
    <Router>
    <Routes>
      <Route
          path="/AddAndEditNews"
          element={
            <ProtectedRoute
              element={
                <div id="root" className="bg-custom">
                  <Header />
                  <div className="container">
                    <AddAndEditNews />
                  </div>
                  <Footer />
                </div>
              }
            />
          }
        />
         <Route
          path="/Home"
          element={
            <ProtectedRoute
              element={
                <div id="root" className="bg-custom">
                  <Header />
                  <div className="container">
                    <Home />
                  </div>
                  <Footer />
                </div>
              }
            />
          }
        />
         <Route
          path="/"
          element={
            <ProtectedRoute
              element={
                <div id="root" className="bg-custom">
                  <Header />
                  <div className="container">
                    <Home />
                  </div>
                  <Footer />
                </div>
              }
            />
          }
        />
         <Route
          path="/ManageUsers"
          element={
            <ProtectedRoute
              element={
                <div id="root" className="bg-custom">
                  <Header />
                  <div className="container">
                    <Manage />
                  </div>
                  <Footer />
                </div>
              }
            />
          }
        />
            <Route
          path="/EditUser"
          element={
            <ProtectedRoute
              element={
                <div id="root" className="bg-custom">
                  <Header />
                  <div className="container">
                    <EditUser />
                  </div>
                  <Footer />
                </div>
              }
            />
          }
        />
         <Route
          path="/ManageSections"
          element={
            <ProtectedRoute
              element={
                <div id="root" className="bg-custom">
                  <Header />
                  <div className="container">
                    <ManageSections />
                  </div>
                  <Footer />
                </div>
              }
            />
          }
        />
        <Route
          path="/ImportNewsFromAI"
          element={
            <ProtectedRoute
              element={
                <div id="root" className="bg-custom">
                  <Header />
                  <div className="container">
                    <ImportNewsFromAI />
                  </div>
                  <Footer />
                </div>
              }
            />
          }
        />

      <Route path="/login" element={<Login />} />      
      <Route path="/" element={<Login />} />    
      <Route path="/signup" element={<Signup />} />  
      <Route path="/PasswordReset" element={<PasswordReset />} />
      <Route path="/ActivateAccount" element={<ActivateAccount />} />
      <Route path="/PasswordResetRequest" element={<PasswordResetRequest />} />
    </Routes>
  </Router>
  );
}

export default App;
