import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { getUserbyId, updateUser } from '../../services/users';
import { getRoles } from '../../services/roles';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

function EditUser() {
    const navigate = useNavigate();
    let user_id = localStorage.getItem('user_id');
    
    // Estado para el formulario
    const [form, setForm] = useState({
        id: 0,
        user_name: '',
        email: '',
        is_active: 0,
        role_id: 0
    });

    // Estado para roles
    const [roles, setRoles] = useState([]);

    // Cargar datos de usuario y roles al montar el componente
    useEffect(() => {
        getRoles().then((data) => {
            setRoles(data);
        }).finally(() => {
            getUserbyId(user_id).then((data) => {
                console.log(data)
                setForm({
                    id: data.id,
                    user_name: data.user_name,
                    email: data.email,
                    is_active: data.is_active ? 1 : 0,
                    role_id: data.role_id
                });
            });
        });
    }, [user_id]);
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value
        });
    };
    const handleCancel = () => {
        navigate('/ManageUsers')
    }    
    const handleSubmit = (e) => {
        e.preventDefault();
        updateUser(form).then((result) => {
                if(result.success)
                {
                    toast.success("Registro actualizado con éxito");
                    setTimeout(()=>{
                        navigate('/ManageUsers')
                    }, 1000);                   
                }
                else
                {
                    toast.error("Algo salio mal!");
                }
        })
    };

    return (
        <>
        <Container className="mt-3">

            <Form >
                <Row className="justify-content-center">
                    <Col xl={6} >
                    <h2>Editar Usuario</h2>
                        <Form.Group controlId="formUserName" className="mb-3">
                            <Form.Label>Nombre de Usuario</Form.Label>
                            <Form.Control
                                type="text"
                                name="user_name"
                                value={form.user_name}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="formEmail" className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={form.email}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="formIsActive" className="mb-3">
                            <Form.Label>¿Activo?</Form.Label>
                            <Form.Check
                                type="checkbox"
                                label="Activo"
                                name="is_active"
                                checked={form.is_active === 1}
                                onChange={(e) => setForm({ ...form, is_active: e.target.checked ? 1 : 0 })}
                            />
                        </Form.Group>

                        <Form.Group controlId="formRole" className="mb-3">
                            <Form.Label>Rol</Form.Label>
                            <Form.Select
                                name="role_id"
                                value={form.role_id}
                                onChange={handleChange}
                            >
                                <option value={0}>Seleccione un rol</option>
                                {roles.map((role) => (
                                    <option key={role.id} value={role.id}>
                                        {role.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        
                        <Button variant="secondary" type="button" className="m-1" onClick={handleCancel}>
                           Cancelar
                        </Button>
                        <Button variant="primary" type="button" className="m-1" onClick={handleSubmit}>
                            Guardar cambios
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Container>
            <ToastContainer />
        </>
    );
}

export default EditUser;