import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Table, Form, } from 'react-bootstrap';
import {  getLast30News } from '../../services/news';
import { getSections, sendUpdateSectionPositionsRequest, getSectionPositions } from '../../services/sections';
import { ToastContainer, toast } from 'react-toastify';



function ManageSections () {
    const [selectedSection, setSelectedSection] = useState(0);
    const [p1Id, setP1Id] = useState(0); // Estado para el ID de la noticia en la posición 1
    const [p2Id, setP2Id] = useState(0);
    const [p3Id, setP3Id] = useState(0);
    const [p4Id, setP4Id] = useState(0);
    const [p5Id, setP5Id] = useState(0);
    const [p6Id, setP6Id] = useState(0);
    const [sections, setSections] = useState([]);
    const [last30News, setLast30News] = useState([]); 
      // Función para manejar cambios en el dropdown de secciones
      const handleSectionChange = async (e) => {
        const sectionId = parseInt(e.target.value, 10);
        setSelectedSection(parseInt(e.target.value, 10));

        if (sectionId !== 0) {
            try {
                const positions = await getSectionPositions(sectionId);
                if (positions) {
                    setP1Id(positions.p1Id || 0);
                    setP2Id(positions.p2Id || 0);
                    setP3Id(positions.p3Id || 0);
                    setP4Id(positions.p4Id || 0);
                    setP5Id(positions.p5Id || 0);
                    setP6Id(positions.p6Id || 0);
                } else {
                    console.warn('No se encontraron posiciones para esta sección');
                }
            } catch (error) {
                console.error('Error al obtener las posiciones de la seción', error);
            }
        }
    };
    const fetchLast30News = async () => {
        const newsData = await getLast30News();
        setLast30News(newsData); // Almacena las noticias en el estado
    };
    const fetchSections = async () => {
        try {
            const sectionsData = await getSections();
            console.log('Secciones recibidas:', sectionsData);
            setSections(sectionsData);
        } catch (error) {
            toast.error('Error al obtener secciones:', error);            
        }
    };
    const updateSectionPositions = async () => {
        if (selectedSection === 0) {
            toast.warning('Por favor, seleccione una sección primero.');            
            return;
        }

        const obj = {
            id: selectedSection, // ID de la sección seleccionada
            p1Id: p1Id !== 0 ? p1Id : null,
            p2Id: p2Id !== 0 ? p2Id : null,
            p3Id: p3Id !== 0 ? p3Id : null,
            p4Id: p4Id !== 0 ? p4Id : null,
            p5Id: p5Id !== 0 ? p5Id : null,
            p6Id: p6Id !== 0 ? p6Id : null
        };

        try {
            const response = await sendUpdateSectionPositionsRequest(obj);
            // console.log('Contenido de response:', response);
    
            if (response && response.success) {
                toast.success('Posiciones actualizadas correctamente.');                
            } else {
                toast.error('Hubo un error al actualizar las posiciones.');                
            }
        } catch (error) {
            toast.error('Hubo un error al actualizar las posiciones. Verifique la consola para más detalles.');       
        }
    };
    useEffect(() => {
        try {
          
            fetchSections();            
            fetchLast30News();
        } catch (error) {
            console.error('Error en useEffect:', error);
        }
    }, []);
    return (
        <>
        <Container>
            <Row className="mt-5">
                <Col>
                    <h1 className="text-center">Noticias</h1>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col xs={12} md={4} className="d-flex align-items-center mb-2">
                    <Form.Select name="sectionId" onChange={handleSectionChange}>
                        <option value={0}>Seleccione una sección</option>
                        {Array.isArray(sections) && sections.length > 0 ? (
                            sections.map((section) => (
                                <option key={section.id} value={section.id}>
                                    {section.name}
                                </option>
                            ))
                        ) : (
                            <option disabled>Cargando secciones...</option>
                        )}
                    </Form.Select>
                </Col>
              
            </Row>

            {/* Dropdowns para mostrar las noticias */}
            <Row className="mt-4">
                {[...Array(6)].map((_, index) => (
                    <Col xs={12} key={index} className="mb-3">
                        <Form.Group controlId={`formNewsPosition${index + 1}`} className="mb-3">
                            <Form.Label>Posición {index + 1}</Form.Label>
                            <Form.Select
                                name={`newsIdP${index + 1}`}
                                value={
                                    index === 0 ? p1Id :
                                        index === 1 ? p2Id :
                                            index === 2 ? p3Id :
                                                index === 3 ? p4Id :
                                                    index === 4 ? p5Id :
                                                        p6Id
                                }
                                onChange={(e) => {
                                    const selectedId = parseInt(e.target.value, 10);
                                    switch (index) {
                                        case 0:
                                            setP1Id(selectedId);
                                            break;
                                        case 1:
                                            setP2Id(selectedId);
                                            break;
                                        case 2:
                                            setP3Id(selectedId);
                                            break;
                                        case 3:
                                            setP4Id(selectedId);
                                            break;
                                        case 4:
                                            setP5Id(selectedId);
                                            break;
                                        case 5:
                                            setP6Id(selectedId);
                                            break;
                                    }
                                }}
                            >
                                <option value={0}>Seleccione una noticia</option>
                                {last30News.map((noticia) => (
                                    <option key={noticia.id} value={noticia.id}>
                                        {noticia.title} - {noticia.short_description}
                                    </option>
                                ))}
                            </Form.Select>

                        </Form.Group>
                    </Col>
                ))}
            </Row>
            {/* Botón para actualizar las posiciones */}
            <Row className="mt-3">
                <Col xs={12} className="text-center">
                    <Button variant="success" onClick={updateSectionPositions}>Actualizar Posiciones</Button>
                </Col>
            </Row>
        </Container>
        <ToastContainer />
        </>
    )
}

export default ManageSections;