import { getAiNews, importNews } from '../../services/aiNews';
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, ListGroup, Form } from 'react-bootstrap';
import AiNewsCard from '../../components/news/AiNewsCard';
import { getActiveCategories } from '../../services/categories';
import { getActiveSubcategories } from '../../services/subcategories';
import { ToastContainer, toast } from 'react-toastify';


function ImportNewsFromAI() {
    const [news, setNews] = useState([]);
    const [qty, setQty] = useState(5);
    const [selectedNews, setSelectedNews] = useState([]); // Estado para noticias seleccionadas
    const [categories, setCategories] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState(0);
    const [subcategories, setSubcategories] = useState([]);
    const [selectedSubcategoryId, setSelectedSubcategoryId] = useState(0);

    const handleSubmit = () => {
        let ids = []
        for(let item of selectedNews)
        {
            ids.push(item.id);
        }
        let obj = { ids, category_id: selectedCategoryId, subcategory_id: selectedSubcategoryId  }
        importNews(obj).then((result) => {
            if(result.success)
            {
                toast.success("Se importaron correctamente las noticias")
                setSelectedCategoryId(0);
                setSelectedSubcategoryId(0);
                for(let item of selectedNews)
                {
                    deselectNews(item.id)
                }
            }
            else
            {
                toast.error("Algo salio mal")
            }
        })
    }
    const loadMoreNews = () => {
        setQty(qty + 5);
    };

    const handleCategoryChange = (e) => {
        const { value } = e.target;    
        let id = parseInt(value);   
        setSelectedCategoryId(id)
        if (id === 0) {
            setSubcategories([]);
            setSelectedSubcategoryId(0)
        } else {
            getActiveSubcategories(id).then((data) => {
                setSubcategories(data);
            })
        }
    }

    const handleSubcategoryChange = (e) => {
        const { value } = e.target; 
        let id = parseInt(value);  
        setSelectedSubcategoryId(id)
    }

    useEffect(() => {
        getAiNews(qty).then((data) => {
            setNews(data);
        });
        getActiveCategories().then((data) => {
            setCategories(data);
        })
    }, [qty]);

    // Función para manejar la selección y deselección
    const handleSelectNews = (id, title) => {
        setSelectedNews((prevSelected) => {
            const isSelected = prevSelected.find(news => news.id === id);
            if (isSelected) {
                return prevSelected.filter(news => news.id !== id);
            } else {
                return [...prevSelected, { id, title }];
            }
        });
    };

    // Función para deseleccionar una noticia
    const deselectNews = (id) => {
        setSelectedNews((prevSelected) => prevSelected.filter(news => news.id !== id));
    };

    return (
        <>
        <Container>
            <Row>
                <Col xl={8} className="scrollable">
                    <Row>
                        {news.length > 0 ?
                            news.map((item, index) => (
                                <Col className='col-xl-6' key={index}>
                                    <AiNewsCard
                                        id={item.id}
                                        title={`${index + 1}. ${item.title}`}
                                        description={item.description}
                                        content={item.content}
                                        categoryName={item.newsCategories.name}
                                        onSelect={handleSelectNews}
                                        selected={!!selectedNews.find(news => news.id === item.id)}
                                    />
                                </Col>
                            )) :
                            null
                        }
                    </Row>
                </Col>
                <Col xl={4}>
                    <Row className='mt-5'>
                        <Col className='text-center'>
                            {/* Botón de importar noticias, deshabilitado según la condición */}
                            <Button 
                                onClick={handleSubmit}
                                disabled={selectedNews.length === 0 || selectedCategoryId === 0}
                            >
                                Importar noticias seleccionadas
                            </Button>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col>
                            <Form.Group>
                                <Form.Label>Seleccionar categoría destino:</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="category_id"
                                    value={selectedCategoryId}
                                    onChange={handleCategoryChange}
                                >
                                    <option key={0} value={0}>Seleccionar categoría</option>
                                    {categories.map((category) => (
                                        <option key={category.id} value={category.id}>{category.name}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col>
                            <Form.Group controlId="formRole">
                                <Form.Label>Subcategoría *:</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="subcategory_id"
                                    value={selectedSubcategoryId}
                                    onChange={handleSubcategoryChange}
                                >
                                    <option key={0} value={0}>Seleccionar subcategoría</option>
                                    {subcategories.map((subcategory) => (
                                        <option key={subcategory.id} value={subcategory.id}>{subcategory.name}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col>
                            {selectedNews.length > 0 ? (
                                <ListGroup>
                                    {selectedNews.map((item) => (
                                        <ListGroup.Item key={item.id}>
                                            {item.title}
                                            <Button variant='link' size="sm" onClick={() => deselectNews(item.id)}>
                                                Eliminar
                                            </Button>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            ) : null}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col className='text-center'>
                    <Button variant='link' onClick={loadMoreNews}>Cargar más noticias</Button>
                </Col>
            </Row>
        </Container>
        <ToastContainer />
        </>
    );
}

export default ImportNewsFromAI;