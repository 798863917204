import React from "react";
import { Card, Form } from 'react-bootstrap';

function AiNewsCard({ id, title, description, content, categoryName, onSelect, selected }) {
    const handleCheckboxChange = () => {
        onSelect(id, title); // Notificamos al padre cuando cambia el estado del checkbox
    };

    return (
        <Card className="mt-2 mb-2">
            <Card.Body>
                <Form.Check 
                    type="checkbox" 
                    label="Seleccionar" 
                    checked={selected} 
                    onChange={handleCheckboxChange} 
                />
                <Card.Title>{title}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{description}</Card.Subtitle>
                <Card.Text>
                    {content}
                </Card.Text>
            </Card.Body>
            <Card.Footer className="text-muted">
                {categoryName}
            </Card.Footer>
        </Card>
    );
}

export default AiNewsCard;